<template lang="pug">
div
  v-container(fill-height, fluid, grid-list-xl)
    v-dialog(
      width="800px"
      v-model="showDialog"
      @click:outside="stopLogs"
      transition="dialog-transition"
    )
      v-card(scrollable)
        v-card-title(class="text-h6 green--text")
          | Timeline da atualização
        v-card-subtitle
          | {{ subTitle }}
        v-divider
        v-card-text( style="overflow-x: overlay; max-height: calc(100vh - 300px)" )
          v-timeline(
            align-top
            dense
          )
            v-timeline-item(
              v-for="log in logs"
              light
              :key="log.idversaolog"
              :color="logError(log.mensagem) ? 'error' : 'success'"
              :icon="logError(log.mensagem) ? 'mdi-close' : 'mdi-check'"
            )
              strong {{ `${log.datahora.split('T')[0].split('-').reverse().join('/')} ${log.datahora.split('T')[1].split('.')[0]}` }}
              div( class="text-h6" ) {{ log.mensagem }}

            v-timeline-item(
              v-if="showLoading"
              small
              icon="mdi-loading"
            )
              v-col(cols="6")
                v-progress-linear(
                  indeterminate
                  color="green"
                  rounded
                  height="6"
                )

        v-divider
        v-card-actions
          v-spacer
          v-btn(
            justify-end
            @click="stopLogs"
            color="primary"
            class="ma-2"
          ) Fechar

  v-layout(justify-center, wrap)
    v-flex(md12)
      DataFilter( :filterProps="filters" :items="dados" :paginate="false" )
        template( v-slot:default="{ items }" )
          v-data-table(
            dense
            item-key="id"
            :headers="fields"
            :items="items"
            :footer-props="{itemsPerPageOptions: [15, 30, 50, items.length], showCurrentPage: true, showFirstLastPage: true, itemsPerPageText: 'Itens por página'}"
          )

            template(v-slot:item="props")
              tr( @click="props.expanded = !props.expanded" )
                td(v-for="field in fields", :key="field.value" )
                  v-tooltip(v-if="field.value == 'statusserver'" bottom)
                    template(v-slot:activator="{ on, attrs }")
                      div(style="text-align: center;")
                        v-chip(
                          v-on="on"
                          v-bind="attrs"
                          :color="props.item[field.value] ? 'success' : 'error'"
                          small
                        )
                    span {{ props.item[field.value] ? 'Conectado' : 'Desconectado' }}

                  v-btn(
                    v-else-if="(field.buttons && field.get(field, props.item).value == true)"
                    v-for="button in field.get(field, props.item).buttons"
                    icon
                    class="mr-3"
                    :outlined="button.text !== null"
                    :color="button.color"
                    :title="button.title"
                    v-bind:key="button.name"
                    v-on:click="button.internalEvent ? $emit(button.eventName, props.item) : button.onClick(props.item)"
                  )
                    v-icon() {{button.icon}}

                  router-link(:to="field.get(field, props.item).to", tag="a", v-if="field.link")
                    | {{ field.get(field, props.item).value }}

                  span(v-if="!field.link && !field.icon && !field.buttons")
                    | {{ field.get(field, props.item).value }}

                  div( class="row no-gutters")
                    v-icon(v-if="field.icon" :color="field.get(field, props.item).color" :title="field.get(field, props.item).title")
                      |  {{ field.get(field, props.item).icon }}

                    div( class="ml-2" ) {{ field.get(field, props.item).title }}



        template( v-slot:actions )
          v-btn(
            icon
            outlined
            class="mr-1"
            color="info"
            title="Buscar"
            @click="getAgendamentos"
          )
            v-icon fa-refresh

          v-btn(
            outlined
            icon
            class="mr-1"
            color="success"
            title="Exportar para excel"
            @click="exportToExcel"
          )
            v-icon fa-file-excel

          v-btn(
            outlined
            icon
            color="success"
            title="Adicionar"
            to="/atualizacao/cadastro"
          )
            v-icon fa-plus

</template>

<script>
import { mapMutations } from 'vuex';
import ExcellentExport from 'excellentexport';
import { versaoAgendamento as apiAgenda, versaoLog as apiLog } from '@/api';
import { socket, state } from '@/socket';

export default {
  data: () => ({
    dados: [],
    fields: apiAgenda.fields,
    order: apiAgenda.order,
    footerButtons: apiAgenda.footerButtons,
    showDialog: false,
    showLoading: false,
    agendamentosInterval: null,

    filters: {},
    logs: [],
    logsInterval: null,
    subTitle: '',
  }),

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    async exportToExcel() {
      const originalHeaders = [...this.fields];
      try {
        this.fields = this.fields.filter(({ value }) => !['cancelar', 'log'].includes(value));

        // Se remover, esse timeout, ocultar as colunas não terá efeito
        await setTimeout(() => { }, 1000);

        const link = document.createElement('a');
        const table = document.getElementsByTagName('table')[0];
        ExcellentExport.excel(link, table, 'pagina 1');
        link.download = this.excelFileName ?? 'gifnoc-excel.xls';
        link.click();
      } finally {
        this.fields = originalHeaders;
      }
    },

    logError(msg) {
      return (msg.toLowerCase().indexOf('erro') != -1) || (msg.toLowerCase().indexOf('problema') != -1);
    },

    async getLogs(idversaoagendamento) {
      this.logs = await apiLog.getByAgendamento(idversaoagendamento);

      if (this.logs.length > 0) {
        const lastLog = this.logs[this.logs.length - 1];

        this.showLoading = (lastLog.mensagem.toLowerCase().indexOf('atualização finalizada') == -1)
          && (lastLog.mensagem.toLowerCase().indexOf('atualização cancelada') == -1)
          && (lastLog.mensagem.toLowerCase().indexOf('erro') == -1);

        clearInterval(this.logsInterval);
      } else {
        this.showLoading = false;
      }
    },

    async initLogs(item) {
      const { idversaoagendamento } = item;
      await this.getLogs(idversaoagendamento);
      this.subTitle = item.nomecliente;
      this.showDialog = true;
      this.logsInterval = setInterval(async (id) => {
        await this.getLogs(id);
      }, 2000, idversaoagendamento);
    },

    stopLogs() {
      clearInterval(this.logsInterval);
      this.subTitle = '';
      this.logs = [];
      this.showDialog = false;
    },

    async getAgendamentos() {
      apiAgenda.refreshAuthProd(this.$store.state.auth);
      try {
        const res = await apiAgenda.getAll();
        res.forEach((item) => {
          item.statusserver = false;
        });
        this.dados = res;
      } catch (e) {
        console.error(e);
        this.$store.dispatch('alert/error', 'Ocorreu um erro ao buscar as informações', { root: true });
      }
    },

    montarFiltros() {
      let clientes = [];
      let versoes = [];
      let usuario = [];

      this.dados.forEach((item) => {
        clientes.push({ field: 'nomecliente', text: item.nomecliente, value: item.nomecliente });
        versoes.push({ field: 'versao', text: item.versao, value: item.versao });
        usuario.push({ field: 'usuario', text: item.usuario, value: item.usuario });
      });

      clientes = clientes.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });

      versoes = versoes.sort((a, b) => {
        const partsA = a.value.split('.').map(Number);
        const partsB = b.value.split('.').map(Number);

        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          const numA = partsA[i] || 0;
          const numB = partsB[i] || 0;

          if (numA < numB) return 1;
          if (numA > numB) return -1;
        }

        return 0;
      });

      usuario = usuario.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });

      this.filters = {
        includeSearch: true,
        items: [
          {
            text: 'Cliente',
            type: 'select',
            values: clientes,
          },
          {
            text: 'Data/Hora inicial',
            type: 'datetime',
            field: 'data',
            value: `${new Date().toISOString().split('T')[0]} 00:00`,
            fn: ([item, filter]) => {
              const dtA = new Date(`${item.data} ${item.hora}`);
              const dtB = new Date(filter.value);
              return (dtA >= dtB);
            },
          },
          {
            text: 'Data/Hora final',
            type: 'datetime',
            field: 'data',
            value: `${new Date().toISOString().split('T')[0]} 23:59`,
            fn: ([item, filter]) => {
              const dtA = new Date(`${item.data} ${item.hora}`);
              const dtB = new Date(filter.value);
              return (dtB >= dtA);
            },
          },
          {
            text: 'Versão',
            type: 'select',
            values: versoes,
          },
          {
            text: 'Status',
            type: 'select',
            values: [
              // ENUM('AGUARDANDO', 'BAIXANDO', 'BAIXADOS', 'EXECUTANDO', 'EXECUTADO', 'ERRO', 'CANCELADO')
              { field: 'status', text: 'Aguardando', value: 'AGUARDANDO' },
              { field: 'status', text: 'Baixando', value: 'BAIXANDO' },
              { field: 'status', text: 'Baixados', value: 'BAIXADOS' },
              { field: 'status', text: 'Executando', value: 'EXECUTANDO' },
              { field: 'status', text: 'Executado', value: 'EXECUTADO' },
              { field: 'status', text: 'Erro', value: 'ERRO' },
              { field: 'status', text: 'Cancelado', value: 'CANCELADO' },
            ],
          },
          {
            text: 'Usuário',
            type: 'select',
            values: usuario,
          },
          {
            text: 'Status CS Server',
            type: 'select',
            values: [
              { field: 'statusserver', text: 'Conectado', value: true },
              { field: 'statusserver', text: 'Desconectado', value: false },
            ],
          }
        ],
      };
    },
  },
  watch: {
    socketConnections() {},
  },

  computed: {
    socketConnections() {
      this.dados.forEach((item) => {
        const index = state.socketConnections.findIndex((row) => item.hash === row.data.token.hash);
        if (index != -1) {
          item.statusserver = true;
        } else {
          item.statusserver = false;
        }
      });

      return state.socketConnections;
    },
  },

  async mounted() {
    this.isControl(false);
    this.setTitle('Agenda de atualizações');
    this.$on('showLog', this.initLogs);

    await this.getAgendamentos();
    this.montarFiltros();
    this.agendamentosInterval = setInterval(() => this.getAgendamentos(), 30000);
  },

  beforeDestroy() {
    clearInterval(this.agendamentosInterval);
  },

  created() {
    this.$store.dispatch('auth/verify');
  },
};
</script>
